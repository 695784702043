import LAYOUT from '@/layout/index.vue';
import { transRoutes, RouteType, type MenuRoute } from '@tencent/iegsp-common/tools/utils/route';
import { QueryType } from '@tencent/iegsp-common/tools/utils/util';
const getRoutes = () => {
  const routes: MenuRoute[] = [
    {
      meta: { icon: 'home', type: RouteType.MENU_SINGLE },
      children: [
        {
          path: '/dashboard/base',
          component: () => import('@/views/dashboard/base/index.vue'),
          meta: { title: $t('首页'), type: RouteType.MENU_ALL },
        },
      ],
    },
    {
      meta: { icon: 'info-circle', type: RouteType.MENU_SINGLE },
      children: [
        {
          path: '/information/info',
          component: () => import('@/views/info/index.vue'),
          meta: { title: $t('信息'), type: RouteType.MENU },
        },
      ],
    },
    {
      meta: { title: $t('待办'), icon: 'notification' },
      children: [
        {
          path: '/order/toQuoted/index',
          meta: { title: $t('待报价'), type: RouteType.MENU },
          component: () => import('@/views/order/toQuoted/index.vue'),
        },
        {
          path: '/order/toReceiveOrders/index',
          meta: { title: $t('待接收订单'), type: RouteType.MENU, old: { path: '/todo/toReceiveOrders' } },
          component: () => import('@/views/order/toReceiveOrders/index.vue'),
        },
        {
          path: '/delivery/toInitiate/index',
          meta: { title: $t('待发起交付'), type: RouteType.MENU, old: { path: '/todo/readyForDelivery' } },
          component: () => import('@/views/delivery/toInitiate/index.vue'),
        },
        {
          path: '/delivery/confirmAmount/index',
          meta: { title: $t('确认验收金额'), type: RouteType.MENU },
          component: () => import('@/views/delivery/confirmAmount/index.vue'),
        },
      ],
    },
    {
      meta: { title: $t('订单管理'), icon: 'menu' },
      children: [
        {
          path: '/order/change/index',
          meta: { title: $t('订单变更'), type: RouteType.MENU },
          component: () => import('@/views/order/change/index.vue'),
        },
      ],
    },
    {
      meta: { title: $t('查询'), icon: 'system-search' },
      children: [
        {
          path: '/order/orderSearch/index',
          meta: { title: $t('订单查询'), type: RouteType.MENU, old: { path: '/search/orderSearch' } },
          component: () => import('@/views/order/orderSearch/index.vue'),
        },
        {
          path: '/delivery/inquiry/index',
          meta: { title: $t('交付查询'), type: RouteType.MENU, old: { path: '/search/deliveryInquiry' } },
          component: () => import('@/views/delivery/inquiry/index.vue'),
        },
      ],
    },
    {
      meta: { title: $t('功能页面') },
      children: [
        {
          path: '/order/details/index',
          meta: {
            title: $t('订单详情'),
            old: { path: '/order/OrderDetail', query: { order_number: QueryType.po_id } },
          },
          component: () => import('@/views/order/details/index.vue'),
        },
        {
          path: '/delivery/businessAcceptance/index',
          component: () => import('@/views/delivery/businessAcceptance/index.vue'),
          meta: {
            title: $t('发起交付'),
            old: {
              path: '/acceptance/businessAcceptance',
              query: { delivery_number: QueryType.dl_id, order_number: QueryType.po_id },
            },
            leave: true,
          },
        },
        {
          path: '/delivery/detail/index',
          component: () => import('@/views/delivery/detail/index.vue'),
          meta: {
            title: $t('交付详情'),
            old: { path: '/delivery/detail', query: { delivery_number: QueryType.dl_id } },
          },
        },
        {
          path: '/result/404',
          component: () => import('@/views/result/404.vue'),
          meta: { title: '404', type: RouteType.ALL },
        },
        {
          // 全部公告
          path: '/dashboard/announcementList/index',
          component: () => import('@/views/dashboard/announcementList/index.vue'),
          meta: { title: $t('公告'), type: RouteType.ALL },
        },
      ],
    },
  ];
  return transRoutes({ routes, LAYOUT });
};

export { getRoutes };
