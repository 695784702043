<template>
  <layout-vue :layout="layout" />
  <safety-tips v-if="!layout.onlyContent" />
</template>
<script lang="tsx" setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SafetyTips from './safetyTips.vue';
import layoutVue from '@tencent/iegsp-common/layout/layout.vue';
import { storeToRefs } from 'pinia';
import { checkHasPermission, useCountStore, useSettingStore, useUserStore } from '@/store';
import { getLocale, LocalCode } from '@tencent/iegsp-common/i18n';
import { getArea, getSiteList } from '@/utils/area';
import { Layout } from '@tencent/iegsp-common/layout/type';
import logo from '@/assets/layout/logo.png';
import { logoutSms } from '@tencent/iegsp-common/tools/utils/login';
import { PermsStatusEnum } from '@tencent/iegsp-common/tools/utils/route';

const { menuRoutes } = storeToRefs(useUserStore());
const { countInfo } = storeToRefs(useCountStore());
const { userInfo, permStatus } = storeToRefs(useUserStore());
const settingStore = useSettingStore();

const router = useRouter();
const route = useRoute();

const status = computed(() => {
  if (permStatus.value === PermsStatusEnum.fail) return '500';
  if (permStatus.value === PermsStatusEnum.success) {
    if (!checkHasPermission(route as any, userInfo.value)) return '403';
  }
  return '200';
});

const layout = computed<Layout>(() => ({
  menuRoutes: menuRoutes.value,
  countInfo: countInfo.value,
  area: getArea(),
  userInfo: {
    name: userInfo.value.name,
    avatar: '',
  },
  lang: getLocale(),
  languages: [
    {
      label: 'English',
      value: LocalCode.en,
    },
    {
      label: '中文',
      value: LocalCode.zh,
    },
  ],
  siteList: getSiteList(),
  route,
  title: $t('腾讯供应链管理系统'),
  logo,
  status: status.value,
  toggleLocale: (lang) => {
    settingStore.updateConfig({ locale: lang });
  },
  logout: () => {
    logoutSms();
  },
  jumpSite: (siteItem) => {
    window.open(`${siteItem.domain}${router.resolve(route).href}`, '_self');
  },
  gotoIndex: () => {
    router.push('/dashboard/base');
  },
}));
</script>
