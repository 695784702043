import { createApp } from 'vue';
import '@/utils/i18n';
import { getI18n, getDataI18n, setDefaultLocale } from '@tencent/iegsp-common/i18n';

import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';
import './tailwind.css';

import { store, useSettingStore, useCountStore, useUserStore } from './store';
import router from './router';
import '@/style/index.less';
import '@/utils/app-info';
import App from './App.vue';
import { PROJECT_CODES } from './config/mytableModel';
import { initNetworkManager } from '@/utils/request';
// import { init as initAegis } from '@/utils/aegis';
import { init as initGalileo } from '@/utils/galileo';
import { commonFilters } from '@tencent/iegsp-common/tools/utils/common-filters';
import { initHelper } from '@/utils/ai-helper';
import { initLogin, setConfig } from '@tencent/iegsp-common/tools/utils/login';
import { getCurrentSite } from '@/utils/area';

setConfig({ localDomain: `https:${getCurrentSite().domain}`, loginType: 'sms' });
initLogin();

initNetworkManager();
window.__PROJECT__ = PROJECT_CODES;

const app = createApp(App);
app.config.globalProperties.$filters = commonFilters;

app.use(TDesign);
app.use(store);
app.use(router);
// initAegis(app);
initGalileo(app);

const settingStore = useSettingStore();
// 如果路由中有语言配置优先使用路由配置
setDefaultLocale(settingStore);

getDataI18n('sscm').finally(() => {});
app.use(getI18n());
app.mount('#app');
useCountStore().getCount();
useUserStore()
  .getUserInfo()
  .then(() => {
    initHelper();
  })
  .catch(() => {});
window.$app = app;
